* {
  user-select: none;
}

img {
  pointer-events: none;
}

html{
  scroll-behavior: smooth;
}