.myCard {
  background-color: transparent;
  width: 190px;
  height: 254px;
  perspective: 1000px;
}

.title {
  font-size: 1.5em;
  font-weight: 900;
  text-align: center;
  margin: 0;
}

.innerCard {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
  cursor: pointer;
}

.myCard:hover .innerCard {
  transform: rotateY(180deg);
}

.frontSide,
.backSide {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border: 1px solid rgba(255, 255, 255, 0.8);
  border-radius: 0.7rem;
  color: white;
  box-shadow: 0 0 0.3em rgba(255, 255, 255, 0.5);
  font-weight: 700;
}

.frontSide,
.frontSide::before {
  background: linear-gradient(
    43deg,
    rgb(65, 88, 208) 0%,
    rgb(200, 80, 192) 46%,
    rgb(255, 204, 112) 100%
  );
}

.backSide,
.backSide::before {
  background-image: linear-gradient(160deg, #0093e9 0%, #80d0c7 100%);
}

.backSide {
  transform: rotateY(180deg);
}

.frontSide::before,
.backSide::before {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  content: '';
  width: 110%;
  height: 110%;
  position: absolute;
  z-index: -1;
  border-radius: 1em;
  filter: blur(20px);
  animation: animate 5s linear infinite;
}

@keyframes animate {
  0% {
    opacity: 0.3;
  }

  80% {
    opacity: 1;
  }

  100% {
    opacity: 0.3;
  }
}

.MoreAboutButton {
  position: relative;
  background-color: transparent;
  color: #e8e8e8;
  font-size: 14px;
  font-weight: 600;
  border-radius: 10px;
  width: 140px;
  height: 50px;
  border: none;
  text-transform: uppercase;
  cursor: pointer;
  overflow: hidden;
  box-shadow: 0 10px 20px rgba(51, 51, 51, 0.2);
  transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);
}

.MoreAboutButton::before {
  content: '... /About';
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  pointer-events: none;
  /* background-color: rgb(0, 152, 255); */
   background: linear-gradient(135deg,rgb(0, 152, 255),rgb(11, 212, 224));
  transform: translate(0%, 90%);
  z-index: 99;
  position: relative;
  transform-origin: bottom;
  transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);
}

.MoreAboutButton::after {
  content: 'More About👨🏻';
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: #333; */
  background: linear-gradient(135deg, rgb(175, 50, 252), rgb(252, 50, 63));

  width: 100%;
  height: 100%;
  pointer-events: none;
  transform-origin: top;
  transform: translate(0%, -100%);
  transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);
}

.MoreAboutButton:hover::before {
  transform: translate(0%, 0%);
}

.MoreAboutButton:hover::after {
  transform: translate(0%, -200%);
}

.MoreAboutButton:focus {
  outline: none;
}

.MoreAboutButton:active {
  scale: 0.95;
}
