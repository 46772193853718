@import url('https://fonts.googleapis.com/css2?family=Borel&family=Edu+SA+Beginner:wght@500&family=Lilita+One&family=Roboto+Condensed&display=swap');

@font-face {
  font-family: 'Abrade';
  src: local('Abrade Regular'), local('Abrade-Regular'),
    url('./Abrade-Regular.woff2') format('woff2'),
    url('./Abrade-Regular.woff') format('woff'),
    url('./Abrade-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
